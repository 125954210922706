import React from "react"
import ModalHeader from "./modal-header"
import { BlackButton } from "../../components/black-buttons"

const ServerDead = (props) => (
	<section className='pa3 pa4-ns'>
		<ModalHeader title="Houston, We Have a Problem" />
		<p className='lh-copy black-90'>
			We're really sorry about this, but we're having some issues processing your order.
		</p>
		<p className='lh-copy black-90'>
			Please try to complete your purchase later.
		</p>
		<div className='tr mt4'>
			<BlackButton title='Okay' onClick={ props.confirm } />
		</div>
	</section>
)

export default ServerDead;