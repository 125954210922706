import includes from 'lodash/includes'
import fetch from 'isomorphic-fetch'

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

class api {
  static headers() {
    return {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'dataType': 'json',
    }
  }

  static host() {
    // return "https://princess.610designstudio.com/";
    // return 'http://localhost:9000/';
    // return "https://610designstudio.com/";
    // return window.location.hostname;
    return '/';
  }

  static checkForLife() {
    const route = 'api/alive';
    const url = `${api.host()}${route}`;
    return this.xhr(url, {}, 'GET');
  }

  static createOrder(params) {
    const route = 'api/createOrder';
    const url = `${api.host()}${route}`;
    return this.xhr(url, params, 'POST');
  }

  static updateOrder(params) {
    const route = 'api/updateOrder';
    const url = `${api.host()}${route}`;
    return this.xhr(url, params, 'POST');
  }

  static completeOrder(params) {
    const route = 'api/completeOrder';
    const url = `${api.host()}${route}`;
    return this.xhr(url, params, 'POST');
  }

  static cancelOrder(params) {
    const route = 'api/cancelOrder';
    const url = `${api.host()}${route}`;
    return this.xhr(url, params, 'POST');
  }

  static fetchOrder(params) {
    const route = 'api/fetchOrder';
    const url = `${api.host()}${route}`;
    return this.xhr(url, params, 'POST');
  }

  static sendRequest(params) {
    const route = 'api/sendRequest';
    const url = `${api.host()}${route}`;
    return this.xhr(url, params, 'POST');
  }

  static contactUs(params) {
    const route = 'api/contactUs';
    const url = `${api.host()}${route}`;
    return this.xhr(url, params, 'POST');
  }

  static post(endpoint, params) {
    const url = `${api.host()}${endpoint}`;
    return this.xhr(url, params, 'POST');
  }

  static xhr(url, params, verb, tag) {
    let options = verb === 'GET' ? { method: verb } : Object.assign({ method: verb }, params ? { body: JSON.stringify(params) } : null );
    options.headers = api.headers();
    // options.credentials = 'include';
    return fetch(url, options)
      // .then(handleErrors)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        // debugger;
        // throw(error);
        // console.error(error);
        return error;
      });
  }

}

export default api