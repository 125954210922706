import cart from "../lib/cart"
import React, { Fragment } from "react"
import Helmet from "react-helmet"
import Footer from "../components/footer"
import Header from "../components/header"
import Notifications from "../components/notifications"
import Modal from "../components/modal"
import "../custom.tachyons.min.css"
import _get from "lodash/get"
import { StripeProvider } from 'react-stripe-elements';

class Layout extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			stripeHeight: 1000,
			stripe: null
		}

		this.ref = null;
		this.setRef = element => {
			this.ref = element;
		};

		cart.initialize();
	}

	componentDidMount() {
		const self = this;
		const key = process.env.STRIPE_API_KEY;
		setTimeout(function(){
			const height = _get(self.ref, 'clientHeight', window.innerHeight) - 15;
			self.setState({ stripeHeight: height });
		}, 60);
		if (window.Stripe) {
	      this.setState({stripe: window.Stripe(key)});
	    } else {
	      document.querySelector('#stripe-js').addEventListener('load', () => {
	        // Create Stripe instance once Stripe.js loads
	        this.setState({stripe: window.Stripe(key)});
	      });
	    }
	}

	// <div className='stripe z-0 bg-stripe top-stripe top-stripe-ns' style={{ minHeight: this.state.stripeHeight + 'px' }}></div>

	render() {
		if(typeof window === 'undefined') {
			return null;
		}
		return (
			<StripeProvider stripe={ this.state.stripe }>
				<>
					<Helmet>
						<title>610 Design Studio</title>
						<meta name="google-site-verification" content="x4beayzQ8aNc6sjXWm-HrKiziquTSe3k20p_Xr9GuBU" />
						<meta name="description" content="610 Design Studio is a laser cutting studio that specializes in event decor and custom design work." />
					</Helmet>
					<div className='sans-serif w-100 pt1 overflow-hidden'>
						<div className='relative z-2 bb-ns b--black-05'>
							<Modal />
							<Notifications />
							<div className='w-100 w-90-m w-80-l w-60-xl center'>
								<Header />
							</div>
						</div>
						<div ref={ this.setRef } className='w-100 w-90-m w-80-l w-60-xl center relative static-ns'>
							<div className='stripe z-0 bg-stripe top-stripe top-stripe-ns' style={{ minHeight: this.state.stripeHeight + 'px' }}></div>
							<div className='relative z-1 pt3 pt1-ns'>{ this.props.children }</div>
						</div>
						<Footer />
					</div>
				</>
			</StripeProvider>
		)
	}
}

export default Layout;
