import events from "../lib/events"
import store from "store"
import _find from "lodash/find"
import _findIndex from "lodash/findIndex"
import _sumBy from "lodash/sumBy"

const PATH = '610.cart';

class cart {
  static get(key) {
    return store.get(key);
  }
  static set(key, val) {
    store.set(key, val);
  }
  static empty() {
    store.set(PATH, []);
    events.emit('cartSize');
  }
  static initialize() {
    const cart = this.get(PATH);
    if(typeof cart === 'undefined') {
      this.set(PATH, []);
    }
  }
  static list() {
    const cart = this.get(PATH);
    if(typeof cart === 'undefined') {
      this.initialize();
      return [];
    }
    return this.get(PATH);
  }
  static contains(id, key) {
    const cart = this.list();
    return _find(cart, [key, id]);
  }
  static size() {
    const cart = this.list();
    if(typeof cart === 'undefined') {
      return 0;
    }
    return _sumBy(cart, (item) => parseInt(item.quantity));
  }
  static add(o) {
    const cart = this.list();
    const item = this.contains(o.comparator, 'comparator');
    if(item) {
      item.quantity = item.quantity + o.quantity;
      cart[item.idx] = item;
    }
    else {
      o.idx = cart.length;
      cart.push(o);
    }
    this.set(PATH, cart);
    events.emit('cartSize');
  }
  static remove(idx) {
    const self = this;
    const cart = this.list();
    return function() {
      if(idx <= cart.length) {
        cart.splice(idx, 1);
        self.set(PATH, cart);
        events.emit('cartSize');
      }
    }
  }
  static updateQuantity(idx, quantity) {
    const cart = this.list();
    const item = cart[idx];
    if(quantity === 0) {
      this.remove(idx)();
      return;
    }
    item.quantity = quantity;
    this.set(PATH, cart);
    events.emit('cartSize');
  }
  static mapped(products) {
    const cart = this.list();
    if(typeof cart === 'undefined') {
      return [];
    }
    return cart.map((o, idx) => {
      const p = products.find(p => p.name === o.name);
      o.product = p;
      return o;
    })
  }
  static sum() {
    const cart = this.list();
    if(cart.length) {
      return _sumBy(cart, item => {
        return parseInt(item.quantity) * parseInt(item.price) * 100;
      });
    }
    return 0;
  }
  static uuid() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,a=>(a^Math.random()*16>>a/4).toString(16));
  }
}
export default cart