import events from "../lib/events";

let source = {
  visible: false
};

class modal {
  static show(component_name, data) {
    source = {
      component: component_name,
      visible: true,
      ...data
    }
    events.emit('modal');
  }
  static hide() {
    source = {
      visible: false
    };
    events.emit('modal');
  }
}
export { source, modal };