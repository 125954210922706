import React from 'react';
import { Elements } from 'react-stripe-elements'
import PaymentForm from "../../components/payment-form"

class Payment extends React.Component {
	constructor(props) {
		super(props);
		const vault = props.retrieve;
		this.state = {
			copy_shipping: vault('billing.copy_shipping', 'on')
		}
	}
	copyShipping = () => {
		const vault = this.props.retrieve;
		this.setState({
			name: vault('shipping.name', ''),
			address1: vault('shipping.address1', ''),
			address2: vault('shipping.address2', ''),
			country: vault('shipping.country', 'United States of America'),
			zip: vault('shipping.zip', ''),
			city: vault('shipping.city', ''),
			state: vault('shipping.state', ''),
			copy_shipping: 'on'
		})
	}
	componentDidMount() {
		if(this.state.copy_shipping) {
			this.copyShipping();
		}
		this.props.store('active_tab', 'Payment');
	}
	componentWillUnmount() {
		this.props.store('billing', this.state);
	}
	updateField = (event) => {
		const new_value = event.target.value;
		this.setState({ [event.target.name]: new_value });
	}
	updateCheckbox = (event) => {
		const new_value = event.target.checked ? "on" : "off";
		if(new_value === 'on') {
			this.copyShipping();
		}
		else {
			this.setState({ [event.target.name]: new_value });
		}
	}
	render() {
		return (
			<Elements>
				<PaymentForm data={ this.state } onInput={ this.updateField } onCheckbox={ this.updateCheckbox } {...this.props} />
			</Elements>
		)
	}
}

export default Payment;