import React from "react"
import Icon from "../components/icon"
import "../tachyons.min.css"

class Footer extends React.Component {
	render() {
		return (
			<div className='bt b--black-05 pv4 pv5-ns z-1 relative'>
				<div className='w-100 w-90-m w-80-l w-60-xl center ph3 ph4-ns cf'>
					<div className='w-100 w-50-ns fr-ns'>
						&nbsp;
						<div className='ba b--black-90 br1 bw1 dn'>
							<p>something</p>
						</div>
					</div>
					<div className='w-100 w-50-ns fr-ns'>
						<div className='db'>
							<a className='dib pr3 black-90 dim' href="https://instagram.com/610designstudio">
								<Icon icon="instagram" style={{ width: 36, height: 36 }} />
							</a>
							<a className='dn pr3 black-90 dim' href="https://facebook.com/610designstudio">
								<Icon icon="facebook" style={{ width: 36, height: 36 }} />
							</a>
						</div>
						<div className='pt4'>
							<h3 className='arvo fw4 ma0 mb3 black-90 f4'>610 Design Studio</h3>
							{/*<p className='pb2 f7 ttu  black-90'>Event decor and custom laser cutting</p>*/}
							<p className='pb2 f6 i black-90 dn'>Event decor and custom laser cutting</p>
							<address className='pb2 black-50 f6 f5-ns lh-copy'><span className='db'>1907 Sabine Street Ste 144 </span>Houston, TX 77007</address>
							<p className='black-50 f7 f6-ns'>&copy; 2018 610 Design Studio</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Footer;