import React from 'react';
import { Link } from "gatsby";

const BlackLink = (props) => (
	<Link className='anim-in-slow bg-black-80 br1 white dim pointer no-underline f6 pa2 ph3 ttu tracked' to={ props.to }>{ props.title }</Link>
)

const BlackLinkOut = (props) => (
	<a className='anim-in-slow bg-black-80 br1 white dim pointer no-underline f6 pa2 ph3 ttu tracked' href={ props.to }>{ props.title }</a>
)

const BlackButton = (props) => (
	<button disabled={ props.disabled || false } className={'anim-in-slow bg-animate br1 bn f6 pa2 ph3 ttu tracked ' + (props.disabled ? 'bg-black-10 black-60' : 'bg-black-80 white pointer dim ')} onClick={ props.onClick }>{ props.title }</button>
)

export { BlackButton, BlackLink, BlackLinkOut };