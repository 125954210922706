import React from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { TextButton, AddressForm, FormLabel, FormInput, FormSelect } from "../components/forms"
import { BlackButton } from '../components/black-buttons'
import Icon from '../components/icon'
import _get from "lodash/get"
import _set from "lodash/set"
import _reduce from "lodash/reduce"

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.length_validation = {
      name: 1,
      address1: 4,
      address2: 0,
      zip: 4,
      city: 2,
      state: 2,
      country: 2,
      copy_shipping: 2
    }
    this.state = {
      has_error: false,
      error_msg: 'error',
      has_interacted: false
    }
  }
  reportErrors = ({error}) => {
    this.setState({
      has_error: error ? true : false,
      error_msg: error ? error.message : 'error',
      has_interacted: true
    })
  }
  ready = () => {
    return this.props.stripe ? true : false;
  }
  checkToken = () => {
    if(!this.props.stripe) {
      return;
    }
    const self = this;
    this.props.stripe.createToken({ type: 'card' }).then(({error, token}) => {
      // console.log(error, token);
      if(!error) {
        self.props.store('card.token', token);
        self.props.store('card.last4', token.card.last4);
        self.props.next();
      }
    });
  }
  valid(){
    const keys = Object.keys(this.props.data);
    const address_valid =  _reduce(keys, (sum, key) => {
      let valid = this.props.data[key].length >= this.length_validation[key];
      if(!valid) {
        return false;
      }
      return sum;
    }, true);
    return address_valid && this.state.has_interacted && !this.state.has_error && !this.props.retrieve('pending', false);
  }
  render() {
    let pending = this.props.retrieve('pending', false);
    return (
      <div>
        <div className='pv2 pt4-ns ph4-ns'>
          <div className='cf'>
            <div className='w-100 w-50-ns fl'>
              <FormLabel title="Card Information" />
            </div>
            <div className='w-100 w-50-ns fl tr-ns'>
              <p className='dib v-mid ma0 pa0 f7 black-40'>Transactions are secure and encrypted.</p>
            </div>
          </div>
          <div className='mt3 mt1-ns'>
            <CardElement className='br1 pa3 f4 bg-black-10' hidePostalCode={ true } onChange={ this.reportErrors } />
            <p className={'ma0 pa0 pv2 pb3-ns f6 ' + (this.state.has_error ? 'red ' : 'o-0')}>{ this.state.error_msg }</p>
          </div>
        </div>
        <div className='cf bt b--black-10'>
          <div className='w-100 w-40-ns fl pt4 pt0-ns pa4-ns'>
            <FormLabel title="Billing Address" />
            <label className='dib dim pointer ba b--black-80 br1 ph3 pv2 mt3'>
              <input className='dn' name="copy_shipping" type="checkbox" value="copy_shipping" checked={ this.props.data.copy_shipping === 'on' } onChange={ this.props.onCheckbox } />
              <Icon className='relative dib v-mid black-80' icon={ this.props.data.copy_shipping === 'on' ? 'check-circle' : 'circle' } style={{ width: '1.5rem', height: '1.5rem' }} />
              <span className='black-90 pl3 dib v-mid f5'>Use Shipping Address</span>
            </label>
          </div>
          <div className='w-100 w-60-ns bl-ns b--black-10 fl ph4-ns pt4 pb0-ns'>
            <AddressForm disabled={ this.props.data.copy_shipping === 'on' } data={ this.props.data } onChange={ this.props.onInput } />
          </div>
        </div>
        <div className='bt b--black-10 pt3 pt4-ns ph4-ns cf pb4'>
          <div className='w-50 fl dn'>
            <TextButton disabled={ pending } icon='chevron-left' title="Go Back" onClick={ this.props.prev } />
          </div>
          <div className='w-100 fl tr'>
            <BlackButton disabled={ !this.valid() } title="Continue" onClick={ this.checkToken } />
            <p className='ma0 pa0 pt2 f7 black-40'>You won't be charged yet. You'll get a chance to review your order before paying on the next screen.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default injectStripe(PaymentForm);