import { Link } from "gatsby"
import cart from "../lib/cart"
import events from "../lib/events"
import React from "react"
import Icon from "../components/icon"

class Header extends React.Component {
	componentDidMount() {
		events.subscribe('cartSize', 'header', this);
	}
	componentWillUnmount() {
		events.unsubscribe('cartSize', 'header');
	}
	render() {
		return (
			<div className='w-100 cf pa3 ph4-ns'>
				<div className='w-100 w-50-m w-30-l w-30-xl fl relative fixed top-0 left-0 static-ns bg-white bg-transparent-ns pv3 pv0-ns'>
					<h1 className='pa0 ma0 arvo fw4 black-90 f4 f-logo-ns pl3 pl0-ns'>610 Design Studio</h1>
					<li className='dib dn-ns v-mid absolute top-1 right-1'><Link activeClassName='black fw6' className='br1 bg-black-90 f6 f5-l f4-xl white pointer pa2 ph3 no-underline dim' to="/cart"><Icon className='relative pr2 v-mid' icon="shopping-cart" style={{ width: 16, height: 16 }} />{ cart.size() }</Link></li>
				</div>
				<div className='w-100 w-70-l w-70-xl tl tr-l fl mt4 pt3 pt0-ns mt0-ns'>
					<ol className='ph0 ma0 pv2 pv1-ns list'>
						<li className='dib pr2 mr4 v-mid mt2 mb1 mv2-ns'><Link activeClassName='black fw6' className='black-90 f6 f5-l f4-xl no-underline dim' to="/">Home</Link></li>
						<li className='dib pr2 mr4 v-mid mt2 mb1 mv2-ns'><Link activeClassName='black fw6' className='black-90 f6 f5-l f4-xl no-underline dim' to="/custom-work/">Custom Work</Link></li>
						<li className='dib pr2 mr4 v-mid mt2 mb1 mv2-ns'><Link activeClassName='black fw6' className='black-90 f6 f5-l f4-xl no-underline dim' to="/shop/">Shop</Link></li>
						<li className='dib pr2 mr4 v-mid mt2 mb1 mv2-ns'><Link activeClassName='black fw6' className='black-90 f6 f5-l f4-xl no-underline dim' to="/faq/">FAQs</Link></li>
						<li className='dib pr2 mr4 v-mid mt2 mb1 mv2-ns'><Link activeClassName='black fw6' className='black-90 f6 f5-l f4-xl no-underline dim' to="/about-us/">About Us</Link></li>
						<li className='dib pr2 mr4 v-mid mt2 mb1 mv2-ns'><Link activeClassName='black fw6' className='black-90 f6 f5-l f4-xl no-underline dim' to="/contact/">Contact</Link></li>
						<li className='dn dib-ns v-mid mt4 mt3-ns mt0-m mt0-l mt0-xl'><Link activeClassName='black' className='br1 bg-black-90 f6 f5-l f4-xl white pointer pa2 ph3 no-underline dim' to="/cart/"><Icon className='relative pr2 v-mid' icon="shopping-cart" style={{ width: 16, height: 16 }} />{ cart.size() }</Link></li>
					</ol>
				</div>
			</div>
		)
	}
}

export default Header;
