import events from "../lib/events";

const queue = [];
let leaving = true;

class messages {
  static show(msg) {
    queue.push(msg);
    leaving = false;
    events.emit('notifications');
    setTimeout(this.prePop, 4000);
    setTimeout(this.pop, 5000);
  }
  static prePop() {
  	leaving = true;
  	events.emit('notifications');
  }
  static pop() {
    queue.pop();
    events.emit('notifications');
  }
}
export { queue, leaving, messages };