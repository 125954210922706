import React from 'react';
import { FormLabel } from "../../components/forms"
import { BlackButton } from "../../components/black-buttons"
import Icon from "../../components/icon"

class Success extends React.Component {
	constructor(props) {
		super(props);
		this.emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
		this.state = {
			email: props.retrieve('email.value')
		}
	}
	componentDidMount() {
		this.props.store('active_tab', 'Order Confirmed');
	}
	splitString (string, size, separator) {
	    var re = new RegExp('.{1,' + size + '}', 'g');
	    return string.match(re).join(separator);
	}
	updateField = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}
	subscribe = () => {

	}
	valid = () => {
		const email = this.state.email;
		return this.emailRegex.test(email);
	}
	render() {
		const vault = this.props.retrieve;
		let number = vault('order_number').toString();
		const order_number = this.splitString(number, 4, '-');
		const method_idx = parseInt(vault('shipping_method', -1));
		const methods = vault('shipping_methods', []);
		const method = methods[method_idx];
		const local_pick_up = method_idx === 2;
		return (
			<div className=''>
				<div className='pv4 pa4-ns'>
					<Icon className='db relative mb3 anim-in-slow' icon="check-circle" style={{ width: 32, height: 32 }} />
					<p className='f3 black-90 ma0 pa0'>Thanks, <span className='anim-in-super-slow'>{ vault('billing.name') }</span>. Your order is confirmed!</p>
					<p className='mb0 pb0 black-80'>Here's your order number: <span className='anim-in-super-slow b'>#{ order_number }</span>.</p>
				</div>
				<div className='cf bt b--black-10 pv4 ph4-ns'>
					<p className='black-80 f4 ma0 pa0 mb3'>Check your inbox.</p>
					<p className='f5 black-80 ma0 pa0 lh-copy'>There's an email headed to <span className='anim-in-super-slow b'>{ vault('email.value') }</span> with your receipt. (It has your order number, too, so don't worry about losing it.)</p>
				</div>
				{ !local_pick_up &&
					<div className='cf bt b--black-10 pv4 ph4-ns'>
						<p className='black-80 f4 ma0 pa0 mb3'>Wait for your package to arrive.</p>
						<p className='f5 black-80 ma0 pa0 lh-copy'>Our average production time is 3 days. When your order is complete, we'll ship it out with <span className='anim-in-super-slow b'>{ method.description }</span>, to be delivered no later than <span className='anim-in-super-slow b'>{ method.estimate.message }</span>.</p>
					</div>
				}
				{ local_pick_up &&
					<div className='cf bt b--black-10 pv4 ph4-ns'>
						<p className='black-80 f4 ma0 pa0 mb3'>Wait to hear from us.</p>
						<p className='f5 black-80 ma0 pa0 lh-copy'>Our average production time is 3 days. When your order is complete, we'll contact you to schedule a time for you to swing by our office and pick it up.</p>
					</div>
				}
				<div className='cf bt b--black-10 pv4 ph4-ns dn'>
					<p className='black-80 f4 ma0 pa0 mb3'>Sign up for our newsletter.</p>
					<p className='f5 black-80 ma0 pa0 lh-copy'>Be the first to see our newest designs. We create new ones every week!</p>
					<div className='pt4 cf'>
						<label className='dib mb3 pb2 w-60 mr2'>
							<FormLabel title='email address' />
							<input required name='email' className={'db black bn f5 w-100 bg-black-10 pa2'} type="text" value={ this.state.email } onChange={ this.updateField } />
						</label>
						<BlackButton title="Subscribe" disabled={ !this.valid() } onClick={ this.subscribe } />
					</div>
				</div>
			</div>
		)
	}
}

export default Success;