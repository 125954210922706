import React from "react"
import ModalHeader from "./modal-header"
import { BlackButton } from "../../components/black-buttons"

class CardDeclined extends React.Component {
	getHelpMessage = (message) => {
		let msg;
		switch(message) {
		  case 'An error occurred while processing your card. Try again in a little bit.':
		    msg = 'This is usually a temporary error. If it persists, please double-check your card information or try a different card.';
		    break;
		   default:
		   	msg = "Please double-check your card information or try another card.";
		}
		return msg;
	}
	render() {
		return (
			<section className='pa3 pa4-ns'>
				<ModalHeader title="Payment Failed" />
				<p className='lh-copy black-90'>
					We're unable to process your payment. Here's why:
				</p>
				<p className='lh-copy black-90'>
					<span className='b'>{ this.props.message }</span>
				</p>
				<p className='lh-copy black-90'>
					{ this.getHelpMessage(this.props.message) }
				</p>
				<div className='tr mt4'>
					<BlackButton title='Okay' onClick={ this.props.dismiss } />
				</div>
			</section>
		)
	}
};

export default CardDeclined;