import React from "react"
import ModalHeader from "./modal-header"
import { BlackButton } from "../../components/black-buttons"

const CartEmpty = (props) => (
	<section className='pa3 pa4-ns'>
		<ModalHeader title="Adiós, Cart" />
		<p className='lh-copy black-90'>
			Your cart is officially empty. We're sorry to see that! Let's get you back to the shop.
		</p>
		<div className='tr mt4'>
			<BlackButton title='Okay' onClick={ props.confirm } />
		</div>
	</section>
)

export default CartEmpty;