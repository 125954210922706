import React from 'react';
import events from "../lib/events"
import { modal } from "../lib/modal"
import cart from "../lib/cart"
import { Link } from "gatsby"
import Icon from "../components/icon"
import InDollars from "../components/in-dollars"
import ProductImage from "../components/product-image"
import _sumBy from "lodash/sumBy"
import _range from "lodash/range"
import _slice from "lodash/slice"

const Order = (props) => {
	let names;
	if(props.item.names) {
		names = props.item.names.join(', ');
		if(props.item.names.length > 10) {
			let first_ten = _slice(props.item.names, 0, 10);
			names = first_ten.join(', ') + ' (and ' + ( props.item.names.length - 10 ) + ' more)';
		}
	}
	const quantity_disabled = props.disabled || (typeof props.can_change_quantity !== 'undefined' && !props.can_change_quantity);
	const range = props.item.category === "menus" ? ([0].concat(_range(5, 101))) : _range(101);
	return (
		<li className={'pa3 cf' + (props.has_border ? ' bb b--black-10' : '')}>
			<div className='w-70 fl'>
				<div className='cf'>
					<div className='w-30 fl mr2'>
						<ProductImage product={ props.product } idx={ 1 } />
					</div>
					<p className='f6 ma0 pa0 mb2 black-90'>{ props.product.fields.vanity_name }</p>
					<p className='f6 ma0 pa0 mb3 black-50'>{ props.product.material }{ props.item.color && <span>, { props.item.color }</span> }</p>
				</div>
				{ props.item.customization &&
					<p className='f6 ma0 pa0 pt2-ns mt1-ns black-50'>Customization: { props.item.customization }</p>
				}
				{ props.item.names &&
					<p className='ma0 pa0 pt2 mb2 f6 black-50'>Names: { names }</p>
				}
				{ props.item.range &&
					<p className='ma0 pa0 pt2 mb2 f6 black-50'>Numbers: { props.item.range[0] } &mdash; { props.item.range[1] }</p>
				}
				{ props.item.size &&
					<p className='f6 ma0 pa0 pt2-ns mt1-ns black-50'>Size: { props.item.size }</p>
				}
				{ props.item.first &&
					<p className='f6 ma0 pa0 pt2-ns mt1-ns black-50'>First Course: <span className='black-80'>{ props.item.first }</span></p>
				}
				{ props.item.second &&
					<p className='f6 ma0 pa0 pt2-ns mt1-ns black-50'>Second Course: <span className='black-80'>{ props.item.second }</span></p>
				}
				{ props.item.third &&
					<p className='f6 ma0 pa0 pt2-ns mt1-ns black-50'>Dessert: <span className='black-80'>{ props.item.third }</span></p>
				}
			</div>
			<div className='w-30 fl tr'>
				{ quantity_disabled &&
					<p className='ma0 pa0 pb2 f4'>
						<span className='f5 pr1'>x</span>
						{ props.item.quantity }
					</p>
				}
				{ !quantity_disabled &&
					<label className={'custom-select custom-select-sm dib br1 pa0 ma0 hover-bg-black-05 bg-animate ba b--black-10'}>
						<select className={'dib black bn f5 w-100 bg-black-10 pa2'} value={ props.item.quantity } onChange={ props.onChange }>
							{ range.map( num =>
								<option key={ num } value={ num }>{ num }</option>
							)}
						</select>
					</label>
				}
				{ !props.disabled &&
					<button className='ma0 pa0 bg-transparent bb bb-only bw1 b--black-10 mt2 pointer dim black-50 f6' onClick={ props.onRemove }>Remove</button>
				}
				<span className='db f4 black-90 mt3 pt1 tr'>${ props.item.price * props.item.quantity }.00</span>
			</div>
			<div className='dn pa3'>
				<p className='arvo fw4 f6 ma0 pa0 mb3 black-90'>{ props.item.productId }</p>
				<p className='f5 ma0 pa0 mb3 black-50 ttu tracked'>{ props.item.color }</p>
				<p className='ma0 pa0 f5 black-50 ttu tracked'>{ props.item.names }</p>
			</div>
		</li>
	)
}


const SummaryRow = (props) => (
	<div className='cf pb1 mb2'>
		<div className='w-50 fl'>
			<p className='ma0 pa0 black-50 f5'>{ props.label }</p>
		</div>
		<div className='w-50 fl tr'>
			{ props.pending &&
				<Icon className='ma0 pa0 dib black-50 anim-rotate' icon={ 'loader' } style={{ width: '0.9rem', height: '0.9rem' }} />
			}
			{ !props.pending && props.children }
		</div>
	</div>
)


class OrderSummary extends React.Component {
	constructor(props) {
		super(props);
		events.subscribe('cartSize', 'orderSummary', this);
	}
	componentWillUnmount() {
		events.unsubscribe('cartSize', 'orderSummary');
	}
	updateQuantity = (idx, item) => {
		const self = this;
		return function(event) {
			let quantity = parseInt(event.target.value);
			if(isNaN(quantity)) {
				quantity = 1;
			}
			if(quantity === 0) {
				self.remove(idx, item)();
				return;
		  	}
		  	self.props.onChange(idx, quantity);
		}
	}
	remove = (idx, item) => {
		const self = this;
		return function(event) {
			modal.show('QUANTITY_ZERO', {
				item: item,
				confirm: () => {
					modal.hide();
					self.props.onChange(idx, 0);
				}
			});
			return;
		}
	}
	renderCartItems() {
		return this.props.items.map((o, idx) => {
			const last = idx === (this.props.items.length - 1);
			return (
				<Order can_change_quantity={ o.category !== 'table_numbers' } disabled={ this.props.disabled } has_border={ !last } key={idx} item={ o } product={ o.product } onChange={ this.updateQuantity(idx, o) } onRemove={ this.remove(idx, o) } />
			)
		})
	}
	render() {
		const props = this.props;
		const pending = props.pending;
		return (
			<div className=''>
				<p className='ma0 pa0 pb2 black-50 f6 tc'>Order Summary</p>
				<div className='ba b--black-10'>
					<ul className='list ma0 pa0 overflow-auto relative' style={{ maxHeight: '330px' }}>
						{ this.renderCartItems() }
					</ul>
					<div className='pa3 bt b--black-10'>
						<SummaryRow pending={ pending } label="Subtotal">
							<p className='ma0 pa0 black-90 f5'>
								<InDollars total={ props.subtotal } />
							</p>
						</SummaryRow>
						<SummaryRow pending={ pending } label="Shipping">
							{ props.shipping === 0 &&
								<p className='ma0 pa0 black-90 f5'>
									Free
								</p>
							}
							{ props.shipping > 0 &&
								<p className='ma0 pa0 black-90 f5'>
									<InDollars total={ props.shipping } />
								</p>
							}
						</SummaryRow>
						<SummaryRow pending={ pending } label="Taxes">
							<p className='ma0 pa0 black-90 f5'>
								<InDollars total={ props.taxes } />
							</p>
						</SummaryRow>
						<div className='cf'>
							<div className='w-50 fl'>
								<p className='ma0 pa0 black-90 b f5'>Total</p>
							</div>
							<div className='w-50 fl tr'>
								{ pending &&
									<Icon className='ma0 pa0 dib black-50 anim-rotate' icon={ 'loader' } style={{ width: '0.9rem', height: '0.9rem' }} />
								}
								{ !pending &&
									<p className='ma0 pa0 black-90 b f5'><InDollars total={ this.props.total } /></p>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default OrderSummary;