import React from 'react';
import cart from "../../lib/cart"
import InDollars from "../../components/in-dollars"
import { FormLabel  } from "../../components/forms"
import { BlackButton } from "../../components/black-buttons"
import HelpText from "../../components/help-text"

class Review extends React.Component {
	componentDidMount() {
		this.props.store('active_tab', 'Review');
	}
	renderDelivery = () => {
		// let local_pick_up = this.props.retrieve('local_pick_up', false);
		let methods = this.props.retrieve('shipping_methods', []);
		let idx = parseInt(this.props.retrieve('shipping_method', -1));
		let method = methods[idx];
		if(idx === 2) {
			return (
				<div>
					<FormLabel title='Shipping Method' />
					<p className='ma0 pa0 pt2 black-90 f4'>Local Pick Up</p>
				</div>
			)
		}
		if(typeof method !== 'undefined') {
			return (
				<div>
					<FormLabel title='Delivery By' />
					<p className='ma0 pa0 mb3 black-90 f4'>{ method.estimate.message }</p>
					<p className='ma0 pa0 mb3 black-50 f5'>{ method.description }</p>
					<p className='ma0 pa0 black-50 f5'>
						{ method.price === 0 &&
							<span>Free</span>
						}
						{ method.price > 0 &&
							<InDollars total={ method.price } />
						}
					</p>
				</div>
			)
		}
	}
	goTo = (idx) => {
		const self = this;
		return function() {
			self.props.cancelOrder();
			self.props.goTo(idx)();
		}
	}
	finish = () => {
		const self = this;
		this.props.finishOrder((response) => {
			cart.empty();
			self.props.next();
		})
	}
	render() {
		const vault = this.props.retrieve;
		const billing = vault('billing.name', '') + ', ' + vault('billing.address1', '') + ', ...';
		const has_address2 = vault('shipping.address2', '').length > 0;
		return (
			<div>
				<div onClick={ this.goTo(0) } className='bl br bt bb-ns b--black-10 pa3 pv4 pa4-ns pointer bg-animate hover-bg-black-05'>
					<FormLabel title='Your Email' />
					<p className='ma0 pa0 black-90 f4'>{ vault('email.value', '') }</p>
				</div>
				<div className='cf ba bb-ns b--black-10'>
					<div className='w-50-ns fl-ns'>
						<div onClick={ this.goTo(1) } className='pa3 pv4 pa4-ns br-ns b--black-10 bg-animate hover-bg-black-05 pointer'>
							<FormLabel title='Shipping To' />
							<p className='ma0 pa0 black-90 f4'>{ vault('shipping.name', '') }</p>
							<p className='ma0 pa0 pt2 pb1 black-50 f5'>{ vault('shipping.address1', '') }{ has_address2 &&
								<span> { vault('shipping.address2', '') }</span>
							}</p>
							<p className='ma0 pa0 pb1 black-50 f5'>{ vault('shipping.city', '') }, { vault('shipping.state', '') } { vault('shipping.zip', '') }</p>
							<p className='ma0 pa0 pb1 black-50 f5'>{ vault('shipping.country', '') }</p>
						</div>
					</div>
					<div className='w-50-ns fl-ns'>
						<div onClick={ this.goTo(2) } className='pa3 pv4 pa4-ns bg-animate hover-bg-black-05 pointer bt b--black-10 bn-ns' style={{ minHeight: '185px'}}>
							{ this.renderDelivery() }
						</div>
					</div>
				</div>
				<div onClick={ this.goTo(3) } className='bb bl br bn-ns b--black-10 pa3 pv4 pa4-ns pointer bg-animate hover-bg-black-05'>
					<FormLabel title='Payment' />
					<p className='ma0 pa0 mb3 black-90 f4'>XXX-XXX-XXX-{ vault('card.last4', '') }</p>
					<p className='ma0 pa0 black-90 f5'>Billing Address: { billing }</p>
				</div>
				<div className='cf pv3 ph4-ns bt-ns b--black-10'>
					<div className='w-100 w-60-ns tl fl'>
						<HelpText className='ma0 pa0 pv3 pv1-ns' pending={ this.props.retrieve('pending', false) }>
							Need to change something? Click a section above.
						</HelpText>
					</div>
					<div className='w-100 w-40-ns fl mt3 mt0-ns tc tr-ns'>
						<BlackButton disabled={ vault('pending') } title="Complete Order" onClick={ this.finish } />
					</div>
				</div>
			</div>
		)
	}
}

export default Review;