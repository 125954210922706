import React from "react"

export default (props) => (
	<header className='pv3'>
		<h3 className='f2 fw4 arvo black-90 ma0 pa0'>
			{ props.title }
		</h3>
		{ props.subtitle &&
			<span className='db ttu tracked black-50 mt2 f6'>{ props.subtitle }</span>
		}
	</header>
);