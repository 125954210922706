import React from "react"
import ModalHeader from "./modal-header"
import { BlackButton } from "../../components/black-buttons"
import { TextButton } from "../../components/forms"
import _slice from 'lodash/slice'

export default (props) => {
	let names;
	if(props.item.names) {
		names = props.item.names.join(', ');
		if(props.item.names.length > 10) {
			let first_ten = _slice(props.item.names, 0, 10);
			names = first_ten.join(', ') + ' (and ' + ( props.item.names.length - 10 ) + ' more)';
		}
	}
	return (
		<section className='pa3 pa4-ns'>
			<ModalHeader title="Are you sure?" subtitle="(Just checking!)" />
			<p className='lh-copy black-90 b'>
				Are you sure you want to remove this item from your cart?
			</p>
			<p className='lh-copy black-90 b'>
				{ props.item.product.name }<br />
				<span className='black-50 fw4'>{ props.item.product.material }{ props.item.color && <span>, { props.item.color }</span> } </span><br />
				{ props.item.customization &&
					<span className='black-50 fw4'>Customization: { props.item.customization }</span>
				}
				{ props.item.size &&
					<span className='black-50 fw4'>Size: { props.item.size }</span>
				}
				{ props.item.names &&
					<span className='black-50 fw4'>Names: { names }</span>
				}
				{ props.item.range &&
					<span className='black-50 fw4'>Numbers: { props.item.range[0] } &mdash; { props.item.range[1] }</span>
				}
				{ props.item.first &&
					<span className='db black-50 fw4'>First Course: { props.item.first }</span>
				}
				{ props.item.second &&
					<span className='db black-50 fw4'>Second Course: { props.item.second }</span>
				}
				{ props.item.third &&
					<span className='db black-50 fw4'>Dessert: { props.item.third }</span>
				}
			</p>
			<div className='cf mt4'>
				<div className='w-50 fl tl'>
					<TextButton icon='x' title="Cancel" onClick={ props.dismiss } />
				</div>
				<div className='w-50 fl tr'>
					<BlackButton title='Remove It' onClick={ props.confirm } />
				</div>
			</div>
		</section>
	)
};