import React from "react"
import ModalHeader from "./modal-header"
import { BlackButton } from "../../components/black-buttons"
import { TextButton } from "../../components/forms"

class AddressComparison extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			address: 'usps'
		}
	}
	updateField = (event) => {
		const new_value = event.target.value;
		this.setState({ [event.target.name]: new_value });
	}
	renderAddress(address) {
		return (
			<span className='dib v-mid black-80'>{ address.address1 } { address.address2 }<br/>{ address.city }, { address.state } { address.zip }</span>
		)
	}
	continue = (e) => {
		e.preventDefault();
		const choice = this.state.address === 'usps' ? this.props.usps : this.props.original;
		this.props.continue(choice);
	}
	render() {
		return (
			<section className='pa3 pa4-ns'>
				<ModalHeader title="Address Check" subtitle="(We'll make it quick)"/>
				<p className='lh-copy black-90 f6 f5-ns'>
					We've found a possible match in the USPS database. <span className='db-ns b'>An accurate address will help your order arrive on time.</span> Please select the best match below.
				</p>
				<div className='mv4 tl'>
					<span className='db black-50 ttu tracked f7 mb1'>USPS Recommendation</span>
					<label htmlFor="usps" className='pointer dim db tl pv2 ph2 ba b--black-10 mb4'>
						<input className='dib mr3 v-mid' type="radio" id="usps" name="address" value="usps" checked={ this.state.address === 'usps' } onChange={ this.updateField } />
						{ this.renderAddress(this.props.usps) }
					</label>
					<span className='db black-50 ttu tracked f7 mb1'>Address as Entered</span>
					<label htmlFor="original" className='pointer dim db tl pv2 ph2 ba b--black-10'>
						<input className='dib mr3 v-mid' type="radio" id="original" name="address" value="original" checked={ this.state.address === 'original' } onChange={ this.updateField } />
						{ this.renderAddress(this.props.original) }
					</label>
				</div>
				<div className='cf mt4'>
					<div className='w-50 fl tl'>
						<TextButton icon='x' title="Cancel" onClick={ this.props.dismiss } />
					</div>
					<div className='w-50 fl tr'>
						<BlackButton title='Continue' onClick={ this.continue } />
					</div>
				</div>
			</section>
		)
	}

}

export default AddressComparison;