import 'babel-polyfill';
import React from 'react';
import moment from "moment";
import { Link } from "gatsby"
import { TextButton } from "../../components/forms"
import { BlackButton } from "../../components/black-buttons"
import Icon from "../../components/icon"
import InDollars from "../../components/in-dollars"
import HelpText from "../../components/help-text"
import _sortBy from "lodash/sortBy";


const DeliveryMethod = (props) => (
	<label htmlFor={ props.method.id } className={'pointer dim mt3 db dib-ns pa3 mr3 br1 fadeinUp ' + (props.chosen === props.value ? ' ba b--black-90' : ' ba b--black-10')}>
		<input className='dib mr3 o-0 fixed' type="radio" id={ props.method.id } name="method" value={ props.value } checked={ props.chosen === props.value } onChange={ props.onChange } />
		{ props.method.estimate !== null &&
			<p className='black-80 ma0 pa0 mb3'>
				<span className='f6 black-50 db mb1'>Arrives By</span>
				{ props.method.estimate.message }
			</p>
		}
		{ props.method.estimate === null &&
			<p className='black-80 ma0 pa0 mb3'>
				<span className='f6 black-50 db mb1'>Pick Up Locally</span>
			</p>
		}
		<span className='black-80 db f4'>
			{ props.method.price === 0 &&
				<span>Free</span>
			}
			{ props.method.price > 0 &&
				<InDollars total={ props.method.price } />
			}
		</span>
	</label>
)

class Delivery extends React.Component {
	componentDidMount() {
		this.props.store('active_tab', 'Delivery');
	}
	valid = () => {
		const pending = this.props.retrieve('pending', false);
		let methods = this.props.retrieve('shipping_methods', []);
		let chosen = this.props.retrieve('shipping_method', 0);
		return chosen >= 0 && !pending && (methods.length > 0);
	}
	pickMethod = (event) => {
		let idx = event.target.value;
		this.props.store('shipping_method', parseInt(idx));
	}
	renderPending = () => {
		const vault = this.props.retrieve;
		const pending = vault('pending', false);
		let methods = vault('shipping_methods', []);
		return (
			<div>
				{ methods.length === 0 && pending &&
					<p className='black-70 i f5'>
						<Icon className='dib mr3 black-50 v-mid anim-rotate' icon={ 'loader' } style={{ width: 22, height: 22 }} />
						<span className='dib v-mid'>Finding your shipping options. Give us just a moment.</span>
					</p>
				}
				{ methods.length === 0 && !pending &&
					<div className='pb4 w-70-ns'>
						<Icon className='db relative mb3 anim-in-slow' icon="alert-circle" style={{ width: 32, height: 32 }} />
						<p className='f4 black-90 ma0 pa0'>We're having trouble loading your shipping options.</p>
						<p className='f5 black-90 pa0 lh-copy'>The good news is that this is usually a temporary problem. The bad news is that until we get it fixed, we can't take your order.</p>
						<p className='f5 black-90 pa0 lh-copy'>We're really sorry about this. If you'd like to place your order another way, or if you need it in a rush, please don't hesitate to <Link className='blue dim no-underline' to="/contact">contact us directly</Link>.</p>
					</div>
				}
			</div>
		)
	}
	renderMessages = () => {
		const mapped = this.props.retrieve('mapped', []);
		let has_sign = mapped.find(prod => prod.category === 'signs');
		let needs_proof = mapped.find(prod => prod.category === 'menus');
		return (
			<div className='mt3'>
				<p className='black-80 mt0 pa0 f6'>
					Our average production time is 3 days.<br/>
				</p>
				{ has_sign &&
					<p className='black-80 mt2 pa0 f6'>
						<span className='b'>Large signs take a week to produce and an additional $10 to ship.</span> This has been reflected in the delivery estimates above.
					</p>
				}
				{ needs_proof &&
					<p className='black-80 mt2 pa0 f6 lh-copy'>
						<span className='b'>Menus require 2 additional business days to produce.</span> This has been reflected in the delivery estimates above. For more information, see our <a target="_blank" className='blue no-underline hover-near-black' href="/faq">digital proof policy</a>.
					</p>
				}
			</div>
		)
	}
	renderMethods = () => {
		const vault = this.props.retrieve;
		let pending = vault('pending', false);
		let method_idx = vault('shipping_method', 0);
		let methods = vault('shipping_methods', []);
		return (
			<div>
				{ !methods.length && this.renderPending() }
				{ methods.length > 0 &&
					<div>
						<HelpText pending={ pending }>
							Choose a delivery option below. Need your order quicker than what you see here?<br/> <a target="_blank" className='blue dim no-underline' href="/contact">Contact us directly.</a>
						</HelpText>
						<div className={'pv2 pt0-ns' + (pending ? ' o-50 ' : ' ')}>
							{ methods.map((method, idx) =>
								<DeliveryMethod key={ method.id } value={ idx } method={ method } chosen={ method_idx } onChange={ this.pickMethod } />
							)}
						</div>
						{ this.renderMessages() }
					</div>
				}
			</div>
		)
	}
	prev = () => {
		this.props.store('local_pick_up', false);
		this.props.prev();
	}
	next = async () => {
		const self = this;
		this.props.createOrder();
		this.props.next();
		// const vault = this.props.retrieve;
		// const methods = this.getMethods();
		// const chosen = vault('shipping.method', '');
		// const final = methods.find(method => method.id === chosen);
		// this.props.store('final_shipping_method', final);
		// const body = {
		// 	order_id: vault('stripe.order_id'),
		// 	to_change: {
		// 		coupon: "local_pick_up"
		// 	}
		// };
		// this.props.updateOrder(body);
		// this.props.store('local_pick_up', true);
		// setTimeout(() => {
		// 	const body = {
		// 		order_id: self.props.retrieve('stripe.order_id'),
		// 		to_change: {
		// 			coupon: ""
		// 		}
		// 	};
		// 	self.props.updateOrder(body);
		// }, 3000)
	}
	render() {
		const vault = this.props.retrieve;
		let pending = vault('pending', false);
		return (
			<div className='pv2 pa4-ns'>
				{ this.renderMethods() }
				<div className='pt4 pt3-ns cf'>
					<div className='w-50 fl dn'>
						<TextButton disabled={ pending } icon={'chevron-left'} title="Go Back" onClick={ this.prev } />
					</div>
					<div className='w-100 fl tr'>
						<BlackButton disabled={ !this.valid() } title="Continue" onClick={ this.next } />
					</div>
				</div>
			</div>
		)
	}
}

export default Delivery;