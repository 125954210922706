import React from "react"
import ModalHeader from "./modal-header"
import { BlackButton } from "../../components/black-buttons"

export default (props) => (
	<section className='pa3 pa4-ns'>
		<ModalHeader title="Something Went Wrong" />
		<p className='lh-copy black-90'>
			We're very sorry &mdash; something has gone wrong in completing your order.
		</p>
		<p className='lh-copy black-90'>
			<span className='b'>You haven't been charged.</span>
		</p>
		<p className='lh-copy black-90'>
			We're investigating the error and will have it fixed soon. Please try again tomorrow. If time is running out, please contact us at <a href="mailto:support@610designstudio.com" target="_blank" className='blue link dim pointer'>support@610designstudio.com</a> and we'll complete the order another way.
		</p>
		<div className='tr mt4'>
			<BlackButton title='Okay' onClick={ props.dismiss } />
		</div>
	</section>
);