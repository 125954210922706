import React from 'react';
import { FormInput } from "../../components/forms"
import { BlackButton } from "../../components/black-buttons"
import HelpText from "../../components/help-text"

class Email extends React.Component {
	constructor(props) {
		super(props);
		this.emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
		this.state = {
			email: this.props.retrieve('email.value', '')
		}
	}
	componentDidMount() {
		this.props.store('active_tab', 'Email');
	}
	componentWillUnmount() {
		this.props.store('email.value', this.state.email);
	}
	updateField = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}
	valid() {
		const pending = this.props.retrieve('pending', false);
		const email = this.state.email;
		return this.emailRegex.test(email) && !pending;
	}
	render() {
		return (
			<div className='pv2 pa4-ns'>
				<div className='w-100 w-60-ns'>
					<FormInput name='email' label='email address' value={ this.state.email } onChange={ this.updateField } />
				</div>
				<HelpText className='mb4' pending={ this.props.retrieve('pending', false) }>
					You'll receive order notifications and receipts at this email address.
				</HelpText>
				<BlackButton title="Continue" disabled={ !this.valid() } onClick={ this.props.next } />
			</div>
		)
	}
}

export default Email;