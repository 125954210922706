import moment from "moment"
import _sortBy from 'lodash/sortBy'

function round2Fixed(value) {
  value = +value;

  if (isNaN(value))
    return NaN;

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + 2) : 2)));

  // Shift back
  value = value.toString().split('e');
  return (+(value[0] + 'e' + (value[1] ? (+value[1] - 2) : -2))).toFixed(2);
}

let parseMethods = (methods, padding) => {
	let now = moment();
	let final_padding = padding ? padding : 3;
	const friendlyEstimate = (estimate) => {
		let delivery = moment(estimate.date).add(final_padding, 'days');
		// let delivery = moment(estimate.date);
		const sunday = delivery.weekday() === 0;
		if(sunday) {
			delivery.add(1, 'days');
		}
		let diff = now.diff(delivery, 'days');
		return {
			days: diff,
			message: delivery.format('ddd, MMM D')
		}
	}
	const base = methods.map(method => {
		return {
			id: method.id,
			price: method.amount,
			estimate: friendlyEstimate(method.delivery_estimate),
			description: method.description
		}
	})
	return _sortBy(base, 'price');
}

export { round2Fixed, parseMethods };