import React from 'react';
import Icon from '../components/icon'
import { States } from '../lib/states'

const TextButton = (props) => (
	<button disabled={ props.disabled } className={'bg-transparent bg-animate black-80 bn f6 pv2 pl0 pr1 br1 ttu tracked' + (props.disabled ? ' o-60' : ' hover-bg-black-10 pointer ')} onClick={ props.onClick }>
		{ props.icon &&
			<Icon className='dib v-mid pr2' icon={ props.icon } style={{ width: 18, height: 18 }} />
		}
		<span className='dib v-mid'>{ props.title }</span>
	</button>
)

class AddressLine2Toggle extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false
		}
	}
	toggle = () => {
		this.setState({
			visible: !this.state.visible
		})
	}
	render() {
		if(!this.state.visible) {
			return (
				<p className='db blue dim pointer f6 ma0 pa0 nt2 mb3 pb2' onClick={ this.toggle }>
					<Icon className='dib v-mid pr2' icon={ "plus-circle" } style={{ width: 18, height: 18 }} />
					<span className='dib v-mid'>Apartment, suite, unit, building, floor, etc. (Optional)</span>
				</p>
			)
		}
		return (
			<FormInput disabled={ this.props.disabled || false } autocomplete='address-line2' name='address2' label="Apartment, suite, etc. (Optional)" value={ this.props.value } onChange={ this.props.onChange } />
		)
	}
}

const ContactForm = (props) => (
	<form onSubmit={ props.onSubmit }>
		<div className=''>
			<FormInput autocomplete='name' name='name' label="Your Full Name" value={ props.data.name } onChange={ props.onChange } />
	    </div>
	    <div className='cf'>
		    <div className='w-100 w-50-ns fl-ns pr4-ns'>
				<FormInput autocomplete='email' name='email' label="Email Address" value={ props.data.email } onChange={ props.onChange } />
		    </div>
		    <div className='w-100 w-50-ns fl-ns'>
		    	<FormInput autocomplete='phone' name='phone' label="Phone Number (Optional)" value={ props.data.phone } onChange={ props.onChange } />
		    </div>
		</div>
	    <div className=''>
	    	<FormTextArea name='message' label="Message" value={ props.data.message } onChange={ props.onChange } minHeight={ 100 } />
	    </div>
	</form>
)

const CustomWorkForm = (props) => (
	<div className='relative'>
		<div className='pa3 pa4-ns cf nb3'>
			<div className='w-100 w-50-ns fl pr3-ns nb2'>
				<FormSelect name='category' label="Product Type" items={ props.categories } value={ props.data.category } onChange={ props.onChange } />
			</div>
			<div className='w-100 w-50-ns fl pl3-ns'>
				<FormInput name='quantity' label="Desired Quantity" value={ props.data.quantity } onChange={ props.onChange } />
			</div>
			<FormTextArea name='description' label="Description" value={ props.data.description } onChange={ props.onChange } minHeight={ 200 } desc="Describe your custom design in as much detail as possible. We'll follow up for more information." />
		</div>
		<div className='bt b--black-20 pa3 pa4-ns nb3'>
			<div className=''><FormInput autocomplete='name' name='name' label="Your Full Name" value={ props.data.name } onChange={ props.onChange } /></div>
			<div className='cf'>
				<div className='w-100 w-50-ns fl pr3-ns'><FormInput autocomplete='email' name='email' label="Email Address" value={ props.data.email } onChange={ props.onChange } /></div>
				<div className='w-100 w-50-ns fl pl3-ns'><FormInput autocomplete='phone' name='phone' label="Phone Number" value={ props.data.phone } onChange={ props.onChange } /></div>
			</div>
		</div>
	</div>
)

const AddressForm = (props) => (
	<div>
		<FormInput disabled={ props.disabled || false } autocomplete='name' name='name' label="Full Name" value={ props.data.name } onChange={ props.onChange } />
		<FormInput disabled={ props.disabled || false } autocomplete='address-line1' name='address1' label="Address Line 1" value={ props.data.address1 } onChange={ props.onChange } />
		<FormInput disabled={ props.disabled || false } autocomplete='address-line2' name='address2' label="Apartment, suite, etc. (Optional)" value={ props.data.address2 } onChange={ props.onChange } />
		<FormInput disabled={ props.disabled || false } autocomplete='postal-code' name='zip' label="Zip" value={ props.data.zip } onChange={ props.onChange } desc={ props.disabled ? "" : "Enter your ZIP code and we'll guess the city and state."} />
		<div className='cf'>
			<div className='w-100 w-50-ns fl-ns'>
				<FormInput disabled={ props.disabled || false } autocomplete='address-level2' name='city' label="City" value={ props.data.city } onChange={ props.onChange } />
			</div>
			<div className='w-100 w-50-ns fl-ns'>
				<FormSelect disabled={ props.disabled || false } autocomplete='address-level1' name='state' label="State" items={ States } value={ props.data.state } onChange={ props.onChange } />
			</div>
		</div>
		<FormInput disabled autocomplete='country-name' name='country' label="Country" value={ props.data.country } onChange={ props.onChange } />
	</div>
)

const FormLabel = (props) => (
	<span className={'db ttu tracked black-50 f6 pb2 bg-transparent'}>{ props.title }</span>
)

const FormInput = (props) => (
	<label className='db mb3 pb2'>
		<FormLabel title={ props.label } />
		<input disabled={ props.disabled } pattern={ props.pattern } autoComplete={ props.autocomplete || null } required name={ props.name } className={'db black bn f5 w-100 ' + ( props.disabled ? 'bg-transparent cursor-disabled pv2' : 'bg-black-10 pa2 ' )} type="text" value={ props.value } onChange={ props.onChange } />
		{ props.desc &&
			<p className='dib v-mid ma0 pa0 pt1 f7 black-50'>{ props.desc }</p>
		}
	</label>
)

const FormSelect = (props) => (
	<div>
		<FormLabel title={ props.label } />
		<label className={'db mb4 custom-select ' + (props.disabled ? ' custom-select-disabled ' : ' bg-black-10 ')}>
			<select disabled={ props.disabled } autoComplete={ props.autocomplete || null } required name={ props.name } className={'db black bn f5 w-100 ' + ( props.disabled ? 'bg-transparent cursor-disabled pv2' : 'bg-black-10 pa2 ' )} value={ props.value } onChange={ props.onChange }>
				{ props.items.map( item =>
					<option key={ item.value } value={ item.value }>{ item.label }</option>
				)}
			</select>
		</label>
	</div>
)

const FormTextArea = (props) => (
	<label className='db mb3 pb2'>
		<FormLabel title={ props.label } />
		<textarea className='db bg-black-10 br1 near-black pa2 bn f5 w-100' name={ props.name } value={ props.value } onChange={ props.onChange } style={{ minHeight: props.minHeight }}/>
		{ props.desc &&
			<p className='dib v-mid ma0 pa0 pt1 f7 black-50'>{ props.desc }</p>
		}
	</label>
)


class FormCourse extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			friendly: false,
			friendly_desc: "Click to edit. (This is just a preview.)"
		}
	}
	componentDidUpdate = (prevProps) => {
		if(this.props.value !== prevProps.value && this.props.value.length === 0) {
			this.showUgly();
		}
	}
	showFriendly = (e) => {
		const val = this.props.value;
		if(val.length) {
			this.setState({
				friendly: !this.state.friendly,
			})
		}
	}
	showUgly = () => {
		this.setState({
			friendly: false
		})
	}
	render() {
		const props = this.props;
		const ugly = this.props.ugly_desc;
		return (
			<label className='db mb3 pb2'>
				<FormLabel title={ props.label } />
				{ this.state.friendly &&
					<p onClick={ this.showUgly } className='ba b--black-20 pa2 tc pre-wrap dim pointer mb0'>{ props.value }</p>
				}
				{ !this.state.friendly &&
					<textarea onBlur={ this.showFriendly } className='db bg-black-10 br1 near-black pa2 bn f5 w-100' name={ props.name } value={ props.value } onChange={ props.onChange } style={{ minHeight: props.minHeight }}/>
				}
				{ this.state.friendly &&
					<p className='dib v-mid ma0 pa0 pt1 f7 black-50'>{ this.state.friendly_desc }</p>
				}
				{ !this.state.friendly && ugly &&
					<p className='dib v-mid ma0 pa0 pt1 f7 black-50'>{ ugly }</p>
				}
			</label>
		)
	}
}


class FormList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			friendly: false,
			ugly_desc: "Enter as many names as you need. Separate each name with a comma.",
			friendly_desc: "Click the list to edit it."
		}
	}
	componentDidUpdate = (prevProps) => {
		if(this.props.value !== prevProps.value && this.props.value.length === 0) {
			this.showUgly();
		}
	}
	showFriendly = (e) => {
		this.setState({
			friendly: !this.state.friendly,
		})
		this.props.validate(e);
	}
	showUgly = () => {
		this.setState({
			friendly: false
		})
	}
	render() {
		const props = this.props;
		const ugly = this.props.ugly_desc || this.state.ugly_desc;
		let friendly_value = props.value.filter(name => name.trim());
		return (
			<label className='db mb3 pb2'>
				<FormLabel title={ props.label } />
				{ this.state.friendly &&
					<ul className='pointer dim list ma0 pa0 bl br bt bb b--black-20 overflow-auto' style={{ maxHeight: '300px' }} onClick={ this.showUgly }>
						{ friendly_value.map((item, idx) =>
							<li className='bb b--black-10 pa2 ph3' key={ idx }>{ item.trim() }</li>
						) }
					</ul>
				}
				{ !this.state.friendly &&
					<textarea onBlur={ this.showFriendly } className='db bg-black-10 br1 near-black pa2 bn f5 w-100' name={ props.name } value={ props.value } onChange={ props.onChange } style={{ minHeight: props.minHeight }}/>
				}
				<p className='dib v-mid ma0 pa0 pt1 f7 black-50'>{ this.state.friendly ? this.state.friendly_desc : ugly }</p>
			</label>
		)
	}
}

export { TextButton, AddressForm, FormLabel, FormInput, FormSelect, FormTextArea, FormList, CustomWorkForm, AddressLine2Toggle, ContactForm, FormCourse };