import React from "react"
import ModalHeader from "./modal-header"
import { BlackButton } from "../../components/black-buttons"

export default (props) => (
	<section className='pa3 pa4-ns'>
		<ModalHeader title="Address Not Found" subtitle="Sorry!" />
		<p className='lh-copy black-90'>
			We verify shipping addresses with the USPS to make sure that we can get your order delivered to you in a timely fashion.
		</p>
		<p className='lh-copy black-90'>
			<span className='b'>Unfortunately, USPS doesn't recognize this address.</span> Please double-check everything is typed correctly. If you still have issues after that, please don't hesitate to contact us at support@610designstudio.com.
		</p>
		<div className='tr mt4'>
			<BlackButton title='Okay' onClick={ props.dismiss } />
		</div>
	</section>
);