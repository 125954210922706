import React from 'react';
import { modal } from "../../lib/modal"
import api from "../../lib/api"
import { TextButton, AddressForm } from "../../components/forms"
import { BlackButton } from "../../components/black-buttons"
import HelpText from "../../components/help-text"
import _capitalize from "lodash/capitalize"
import _lowercase from "lodash/lowercase"
import _reduce from "lodash/reduce"
import _get from "lodash/get"

class Shipping extends React.Component {
	constructor(props) {
		super(props);
		const vault = props.retrieve;
		this.state = {
			name: vault('shipping.name', ''),
			address1: vault('shipping.address1', ''),
			address2: vault('shipping.address2', ''),
			country: vault('shipping.country', 'United States of America'),
			zip: vault('shipping.zip', ''),
			city: vault('shipping.city', ''),
			state: vault('shipping.state', ''),
			has_error: false
		}
		this.length_validation = {
			name: 1,
			address1: 4,
			address2: 0,
			zip: 4,
			city: 2,
			state: 2,
			country: 2,
			speed: 0
		}
	}
	componentDidMount() {
		this.props.store('active_tab', 'Shipping');
	}
	componentWillUnmount() {
		this.props.store('shipping', this.state);
	}
	next = () => {
		const method = this.props.retrieve('shipping_method', 0);
		const u = [
			{key: 'shipping', value: this.state},
			{key: 'pending', value: false},
			{key: 'shipping_method', value: method}
		];
		this.props.batch(u);
		this.props.next();
	}
	updateField = (event) => {
		const new_value = event.target.value;
		this.setState({ [event.target.name]: new_value });
		if(event.target.name === 'zip') {
			if(new_value.length >= 5 && parseInt(new_value)) {
				this.fetchState(new_value);
			}
		}
		else if(event.target.name === 'speed') {
			this.props.store('shipping.speed', new_value);
		}
		else if(event.target.name === 'state') {
			this.props.store('shipping.state', new_value);
		}
	}
	fetchState = async (code) => {
		let zip = code.toString().slice(0, 5);
		const response = await api.post('api/stateFromZip', { zip: zip });
		const city = _get(response, 'city', '');
		const state = _get(response, 'state', '');
		this.setState({
			city: _capitalize(city),
			state: state
		});
		this.props.store('shipping.state', state);
		// console.log(response);
	}
	equalAddresses = (USPS, original) => {
		return _lowercase(USPS.address1) === _lowercase(original.address1) &&
			_lowercase(USPS.address2) === _lowercase(original.address2) &&
			_lowercase(USPS.city) === _lowercase(original.city) &&
			_lowercase(USPS.state) === _lowercase(original.state) &&
			_lowercase(USPS.zip) === _lowercase(original.zip);
	}
	verifyAddress = async () => {
		const self = this;
		try {
			this.props.store('pending', true);
			const response = await api.post('api/verifyAddress', this.state);
			// console.log(response);
			const desc = _get(response, 'Description', false);
			if(desc) {
				// Error — address not found.
				this.props.store('pending', false);
				modal.show('ADDRESS_NOT_FOUND', {});
				this.setState({
					has_error: true
				})
			}
			else {
				this.props.store('shipping.has_error', false);
				const usps = {
					address1: response.street1,
					address2: response.street2,
					city: response.city,
					state: response.state,
					zip: response.zip
				}
				if(!this.equalAddresses(usps, this.state)) {
					const updateAddress = function(address) {
						self.setState({
							address1: address.address1,
							address2: address.address2,
							city: address.city,
							state: address.state,
							zip: address.zip
						}, () => {
							modal.hide();
							self.next();
						});
					}
					modal.show('ADDRESS_COMPARISON', {
						original: this.state,
						usps: usps,
						continue: updateAddress
					});
				}
				else {
					this.next();
				}
			}
		} catch(error) {
			this.props.store('pending', false);
		}
	}
	valid = () => {
		const pending = this.props.retrieve('pending', false);
		if(pending) {
			return false;
		}
		const keys = Object.keys(this.state);
		keys.pop();
		return parseInt(this.state.zip) && _reduce(keys, (sum, key) => {
			let valid = this.state[key].length >= this.length_validation[key];
			if(!valid) {
				return false;
			}
			return sum;
		}, true);
	}
	validate = () => {
		if(this.valid()) {
			this.verifyAddress();
		}
	}
	render() {
		let pending = this.props.retrieve('pending', false);
		return (
			<section className='pv2 pa4-ns'>
				<form className='cf'>
					<div className='w-100 w-40-ns mb4 mb0-ns fr-ns pl3-ns'>
						<HelpText className='ma0' split={ true } pending={ this.props.retrieve('pending', false) }>
							Enter your shipping address here. You'll pick your delivery options on the next screen.
						</HelpText>
					</div>
					<div className='w-100 w-60-ns fr-ns pr3-ns'>
						<AddressForm data={ this.state } onChange={ this.updateField } />
					</div>
				</form>
				<div className='pt4 pt3-ns cf'>
					<div className='w-50 fl dn'>
						<TextButton disabled={ pending } icon={'chevron-left'} title="Go Back" onClick={ this.props.prev } />
					</div>
					<div className='w-100 fl tr'>
						<BlackButton disabled={ !this.valid() } title="Continue" onClick={ this.validate } />
					</div>
				</div>
			</section>
		)
	}
}

export default Shipping;