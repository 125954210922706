import React from 'react';
import { Link } from "gatsby";
import events from "../lib/events";
import {queue, leaving, messages} from "../lib/messages";
import Icon from "../components/icon"


const EmailFailed = (props) => (
  <p className='ma0 pa3 ph4-ns white f5'>
    Sorry! Something went wrong on our side. Please try and submit your inquiry later.
  </p>
)

const ContactSent = (props) => (
  <p className='ma0 pa3 ph4-ns white f5'>
    Your question has been received. Thanks for reaching out! We respond to all questions within 1 business day, so keep an eye on your inbox.
  </p>
)

const RequestSent = (props) => (
  <p className='ma0 pa3 ph4-ns white f5'>
    Request sent! Thanks for that. We respond to all inquiries within 48 hours, so keep an eye on your inbox.
  </p>
)

const AddedToCart = (props) => (
  <Link className='db white f5 pointer dim pa3 ph4-ns' to="/cart">
    <span className='dib w-50 v-mid '>Added to Cart.</span>
    <span className='dib w-50 v-mid tr'>
      <Icon className='dib relative v-mid anim-in-slow white' icon="arrow-right-circle" style={{ width: '1.25rem', height: '1.25rem' }} />
    </span>
  </Link>
)


class Notifications extends React.Component {
  componentDidMount() {
    events.subscribe('notifications', 'notifications', this);
  }
  componentWillUnmount() {
    events.unsubscribe('notifications', 'notifications');
  }
  getMessage(type) {
    switch(type) {
      case 'ADDED_TO_CART':
        return <AddedToCart />
        break;
      case 'CONTACT_SENT':
        return <ContactSent />
        break;
      case 'CONTACT_FAILED':
        return <EmailFailed />
        break;
      case 'REQUEST_SENT':
        return <RequestSent />
        break;
      case 'REQUEST_FAILED':
        return <EmailFailed />
        break;
    }
  }
  render() {

    const margin = leaving ? '-100%' : '0px';
    const msg_type = queue[queue.length - 1];
    const msg = this.getMessage(msg_type);
    const width = msg_type === 'ADDED_TO_CART' ? 'w-25-ns' : 'w-third-ns';

    return (
      <div className={'fixed top-0 right-0 w-100 z-3 anim-margin ' + width} style={{ marginRight: margin }}>
        <div className='w-100' style={{ backgroundColor: '#332E2E' }}>
          { msg }
        </div>
      </div>
    )

  }
}

export default Notifications;