import React from "react"
import moment from "moment"
import { graphql, Link, navigate } from "gatsby"
import api from "../lib/api"
import events from "../lib/events"
import { modal } from "../lib/modal"
import cart from "../lib/cart"
import Layout from "../components/layout"
import Header from "../components/header"
import PageTitle from "../components/page-title"
import OrderSummary from "../components/order-summary"
import _sumBy from "lodash/sumBy"
import _get from "lodash/get"
import _set from "lodash/set"
import _filter from "lodash/filter"

import HelpText from "../components/help-text"
import Success from "../components/checkout/success"
import Review from "../components/checkout/review"
import Payment from "../components/checkout/payment"
import Delivery from "../components/checkout/delivery"
import Shipping from "../components/checkout/shipping"
import Email from "../components/checkout/email"

class Tabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: 0
		}
	}
	isActive = (idx) => {
		const has_padding = idx < 4 ? ' pr3-l ' : '';
		return idx === this.state.active ? ('b dib ' + has_padding) : ' dn dib-l ';
	}
	hasBorder = (idx) => {
		return idx <= this.state.active ? 'bb b--black-10 bw2 ' : '';
	}
	styling = (idx) => {
		return this.isActive(idx) + this.hasBorder(idx);
	}
	previous = () => {
		this.setState({ active: this.state.active - 1 })
	}
	next = async () => {
		this.setState({ active: this.state.active + 1 })
		try {
			const response = await api.checkForLife();
		} catch(e) {
			this.props.store('server_alive', false);
			modal.show('SERVER_DEAD', {
				confirm: () => {
					setTimeout(() => {
						navigate('/cart/');
					}, 20);
					modal.hide();
				}
			})
		}
	}
	goTo = (idx) => {
		const self = this;
		return function() {
			self.setState({
				active: idx
			})
		}
	}
	renderPage = (props) => {
		const pages = [<Email {...props} />,<Shipping {...props} />,<Delivery {...props} />,<Payment {...props} />,<Review {...props} />,<Success {...props} />];
		return pages[this.state.active];
	}
	render() {
		const page_props = {
			prev: this.previous,
			next: this.next,
			goTo: this.goTo,
			...this.props
		}
		return (
			<div className='pr4-ns'>
				<ol className='dn db-ns list pa0 ma0 cf'>
					<li className={'w-100 w-20-l f6 fl ' + this.isActive(0)}><span className={'db black-90 pb1 ' + this.hasBorder(0)}><span className='fw4 pr2 black-50'>1.</span>Email</span></li>
					<li className={'w-100 w-20-l f6 fl ' + this.isActive(1)}><span className={'db black-90 pb1 ' + this.hasBorder(1)}><span className='fw4 pr2 black-50'>2.</span>Shipping</span></li>
					<li className={'w-100 w-20-l f6 fl ' + this.isActive(2)}><span className={'db black-90 pb1 ' + this.hasBorder(2)}><span className='fw4 pr2 black-50'>3.</span>Delivery</span></li>
					<li className={'w-100 w-20-l f6 fl ' + this.isActive(3)}><span className={'db black-90 pb1 ' + this.hasBorder(3)}><span className='fw4 pr2 black-50'>4.</span>Payment</span></li>
					<li className={'w-100 w-20-l f6 fl ' + this.isActive(4)}><span className={'db black-90 pb1 ' + this.hasBorder(4)}><span className='fw4 pr2 black-50'>5.</span>Review &amp; Purchase</span></li>
				</ol>
				<div className='bn ba-ns b--black-10'>
					{ this.renderPage(page_props) }
				</div>
			</div>
		)
	}
}

class Checkout extends React.Component {
	constructor(props) {
		super(props);
		events.subscribe('cartSize', 'cartPage', this);
		this.products = props.data.products.edges.map(item => item.node);
		this.state = {
			vault: {
				total: cart.sum(),
				order: cart.list(),
				mapped: cart.mapped(this.products),
				products: this.products,
				meta: {
					server_alive: true,
					qualifies_for_free_shipping: false
				}
			}
		}
		this.updateMethods();
		this.state.vault.shipping_method = 0;
	}
	componentDidMount() {
		this.checkCart();
	}
	inTexas = () => {
		let state = this.inVault('shipping.state', '');
		state = state.toLowerCase();
		return state === "tx" || state === "Texas";
	}
	hasSign = () => {
		const mapped = this.inVault('mapped', []);
		return mapped.find(prod => prod.category === 'signs');
	}
	updateMethods = () => {
		const has_sign = this.hasSign();
		let total = this.inVault('total', 0);
		let padding = this.getShippingPadding();
		const friendlyEstimate = (description) => {
			let now = moment();
			let production_date = now.add(padding, 'days');
			let delivery_padding = description === "Standard Shipping" ? 7 : 3;
			let delivery_date = production_date.add(delivery_padding, 'days');
			// let delivery = moment(estimate.date);
			const sunday = delivery_date.weekday() === 0;
			if(sunday) {
				delivery_date.add(1, 'days');
			}
			let diff = now.diff(delivery_date, 'days');
			return {
				days: diff,
				message: delivery_date.format('ddd, MMM D')
			}
		}
		let in_dollars = total / 100;
		// in_dollars > 100
		const shipping_0 = {
			id: 'shipping_free',
			price: 0,
			estimate: friendlyEstimate("Standard Shipping"),
			description: "Standard Shipping"
		}
		// in_dollars < 100, no sign
		const shipping_5 = {
			id: 'shipping_5',
			price: 500,
			estimate: friendlyEstimate("Standard Shipping"),
			description: "Standard Shipping"
		}
		// in_dollars > 100, sign
		const shipping_10 = {
			id: 'shipping_10',
			price: 1000,
			estimate: friendlyEstimate("Standard Shipping"),
			description: "Standard Shipping"
		}
		// in_dollars < 100, sign
		const shipping_15 = {
			id: 'shipping_15',
			price: 1500,
			estimate: friendlyEstimate("Standard Shipping"),
			description: "Standard Shipping"
		}
		// in_dollars < 100, no sign
		const shipping_25 = {
			id: 'shipping_25',
			price: 2500,
			estimate: friendlyEstimate("Express Shipping"),
			description: "Express Shipping"
		}
		// in_dollars < 100, sign
		const shipping_35 = {
			id: 'shipping_35',
			price: 3500,
			estimate: friendlyEstimate("Express Shipping"),
			description: "Express Shipping"
		}
		const local = {
			id: 'local_pick_up',
			price: 0,
			estimate: null,
			description: "Local Pick Up"
		}
		// return [standard, rush, local];
		let methods;
		if(has_sign) {
			if(in_dollars >= 100) {
				methods = [shipping_10, shipping_35, local];
			}
			else {
				methods = [shipping_15, shipping_35, local];
			}
		}
		else {
			if(in_dollars >= 100) {
				methods = [shipping_0, shipping_25, local];
			}
			else {
				methods = [shipping_5, shipping_25, local];
			}
		}
		this.updateVault('shipping_methods', methods);
	}
	getShippingPadding = () => {
		const mapped = this.inVault('mapped', []);
		let has_sign = mapped.find(prod => prod.category === 'signs');
		let needs_proof = mapped.find(prod => prod.category === 'menus');
		if(has_sign) {
			return 7;
		}
		if(needs_proof) {
			return 5;
		}
		return 3;
	}
	checkCart = () => {
		const size = parseInt(cart.size());
		const paid = this.inVault('stripe.status', '') === 'paid';
		if(size === 0 && !paid) {
			setTimeout(() => {
				navigate('/cart');
			}, 100)
		}
	}
	componentWillUnmount = () => {
		events.unsubscribe('cartSize', 'cartPage');
		const status = this.inVault('stripe.status', '');
		if(status !== 'paid') {
			this.cancelOrder();
		}
	}
	inVault = (key, ifNot) => {
		return _get(this.state.vault, key, ifNot);
	}
	batchUpdate = (pairs, callback) => {
		const self = this;
		const vault = this.state.vault;
		pairs.forEach(pair => {
			_set(vault, pair.key, pair.value);
		})
		this.setState({
			vault: vault
		}, callback || function(){});
	}
	updateVault = (key, value) => {
		const vault = this.state.vault;
		_set(vault, key, value);
		this.setState({
			vault: vault
		})
	}
	hasOrder = () => {
		return this.inVault('stripe.order_id', false);
	}
	cancelOrder = () => {
		if(this.hasOrder()) {
			try {
				const body = {
					order_id: this.inVault('stripe.order_id', null)
				}
				api.cancelOrder(body);
				this.updateVault('stripe.order_id', false);
			}
			catch (error) {

			}
		}
	}
	createOrder = async (callback) => {
		this.updateVault('pending', true);
		this.cancelOrder();
		try {
			const response = await api.createOrder(this.state.vault);
			let updates = [
				{ key: 'stripe', value: { order_id: response.id, items: response.items, amount: response.amount }},
				{ key: 'pending', value: false }
			];
			this.batchUpdate(updates);
			if(typeof callback !== 'undefined') {
				callback(response);
			}
		} catch(error) {
			this.updateVault('pending', false);
		}
	}
	updateOrder = async (body) => {
		this.updateVault('pending', true);
		try {
			const response = await api.updateOrder(body);
			let updates = [
				{ key: 'stripe', value: { order_id: response.id, items: response.items, amount: response.amount }},
				{ key: 'shipping.method', value: response.selected_shipping_method },
				{ key: 'pending', value: false }
			];
			this.batchUpdate(updates);
			return response;
		} catch(error) {
			this.updateVault('pending', false);
		}
	}
	onQuantityChange = (idx, quantity) => {
		const self = this;
		cart.updateQuantity(idx, quantity);
		if(cart.size() === 0) {
			modal.show('CART_EMPTY', {
				confirm: () => {
					setTimeout(() => {
						navigate('/shop/');
					}, 20);
					modal.hide();
				}
			});
		}
		this.batchUpdate([
			{ key: 'order', value: cart.list() },
			{ key: 'mapped', value: cart.mapped(this.products) },
			{ key: 'total', value: cart.sum() }
		]);
		this.updateMethods();
		if(this.hasOrder()) {
			this.createOrder((response) => {
				// create a NEW order, unfortunately
				// and then do things depending on what tab we're on. sigh.
				// cart.updateQuantity(idx, quantity);
			})
		}
	}
	sum = (key) => {
		const items = this.inVault('stripe.items', []);
		const of_type = _filter(items, ['type', key]);
		if(of_type.length) {
			return _sumBy(of_type, 'amount');
		}
		return 0;
	}
	calcTax = () => {
		if(this.hasOrder()) {
			return this.sum('tax');
		}
		if(!this.inTexas()) {
			return 0;
		}
		let sub = this.calcSub();
		let shipping = this.calcShipping();
		return 0.0825 * (sub + shipping);
	}
	calcSub = () => {
		let sub = 0;
		if(this.hasOrder()) {
			let items = this.inVault('stripe.items', []);
			const of_type = _filter(items, (i) => { return i.type === 'sku' && i.description !== "Shipping" } );
			if(of_type.length) {
				sub = _sumBy(of_type, 'amount');
			}
		}
		else {
			sub = cart.sum();
		}
		return sub;
	}
	calcShipping = () => {
		const total = this.inVault('total', 0);
		const methods = this.inVault('shipping_methods', []);
		const chosen = this.inVault('shipping_method', -1);
		if(total === 0) {
			return 0;
		}
		if(chosen >= 0) {
			const method = methods[chosen];
			if(typeof method !== 'undefined') {
				return method.price;
			}
		}
		return 0;
	}
	calcTotal = () => {
		if(this.hasOrder()) {
			return this.inVault('stripe.amount', cart.sum());
		}
		let total = cart.sum();
		total += this.calcShipping();
		total += this.calcTax();
		return total;
	}
	finishOrder = async (callback) => {
		this.updateVault('pending', true);
		const order_id = this.inVault('stripe.order_id', false);
		const token = this.inVault('card.token', false);
		const mapped = this.inVault('mapped', []);
		const method_idx = this.inVault('shipping_method', -1);
		const methods = this.inVault('shipping_methods', []);
		const body = { order_id, token, mapped };
		if(!order_id || !token || method_idx < 0) {
			// Somehow got here without the order id or card token, so...we're screwed.
			return;
		}
		try {
			const method = methods[method_idx];
			let u = {
				order_id: order_id,
				to_change: {
					metadata: {
						buyer_name: this.inVault('billing.name'),
						last_four: this.inVault('card.last4'),
						shipping_method: JSON.stringify(method)
					}
				}
			};
			const update = await this.updateOrder(u);
			this.updateVault('pending', true);
			const response = await api.completeOrder(body);
			if(response.status === 'paid') {
				this.batchUpdate([
					{ key: 'stripe.status', value: 'paid'},
					{ key: 'order_number', value: response.number },
					{ key: 'pending', value: false },
				], callback);
			}
			else {
				this.updateVault('pending', false);
				modal.show('CARD_DECLINED', {
					message: response.message,
					code: response.code
				});
			}
		} catch(error) {
			console.log(error);
			this.updateVault('pending', false);
			modal.show('PURCHASE_FAILED', {
			});
		}
	}
	// onUpdate = () => {
	// 	this.checkCart();
	// }
	render() {
		const order_paid = this.inVault('stripe.status', false) === 'paid';
		const last_page = this.inVault('active_tab', '') === 'Review';
		const disabled = order_paid || last_page;
		return (
			<Layout>
				<div className='ph3 ph4-ns pb4-ns pt3 pt0-ns'>
					<PageTitle title="Checkout" subtitle={ this.inVault('active_tab', '') }></PageTitle>
					<div className='cf pb5'>
						<div className='w-100 w-70-ns fl-ns'>
							<Tabs
								vault={ this.state.vault }
								store={ this.updateVault }
								batch={ this.batchUpdate }
								retrieve={ this.inVault }
								finishOrder={ this.finishOrder }
								createOrder={ this.createOrder }
								updateOrder={ this.updateOrder }
								cancelOrder={ this.cancelOrder }
							/>
						</div>
						<div className='w-100 w-30-ns fl-ns pt5 pt0-ns'>
							<OrderSummary
								taxes={ this.calcTax() }
								shipping={ this.calcShipping() }
								subtotal={ this.calcSub() }
								total={ this.calcTotal() }
								items={ this.inVault('mapped', []) }
								products={ this.products }
								onChange={ this.onQuantityChange }
								pending={ this.inVault('pending', false) }
								disabled={ disabled }
								choseShipping={this.inVault('shipping_method', -1)}
							/>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Checkout;

export const query = graphql`
	query Checkout {
		products: allAirtableProducts {
			totalCount
			edges {
				node {
					name
					category
					material
					base_price
					premium_price
					stripe_id
					test_stripe_id
					sku_ids
					test_sku_ids
					fields {
						slug
						airtable_id
						path
						category_slug
						vanity_name
					}
				}
	  		}
		}
  }
`