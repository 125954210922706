import React from 'react';
import events from "../lib/events";
import { source, modal } from "../lib/modal";
import AddressNotFound from "../components/modals/address-not-found";
import AddressComparison from "../components/modals/address-comparison";
import CardDeclined from "../components/modals/card-declined";
import CartEmpty from "../components/modals/cart-empty";
import PurchaseFailed from "../components/modals/purchase-failed";
import QuantityZero from "../components/modals/quantity-zero";
import ServerDead from "../components/modals/server-dead";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.ref = null;
    this.setRef = element => {
      this.ref = element;
    };
    this.state = {
      top: -1000
    }
  }
  componentDidMount() {
    events.subscribe('modal', 'modal', this);
  }
  componentWillUnmount() {
    events.unsubscribe('modal', 'modal');
  }
  dismiss = () => {
    modal.hide();
  }
  renderComponent(name) {
    const props = {
      dismiss: this.dismiss,
      ...source
    }
    switch(name) {
      case 'ADDRESS_NOT_FOUND':
        return <AddressNotFound { ...props } />
      case 'ADDRESS_COMPARISON':
        return <AddressComparison { ...props } />;
      case 'CARD_DECLINED':
        return <CardDeclined { ...props } />;
      case 'CART_EMPTY':
        return <CartEmpty { ...props } />;
      case 'QUANTITY_ZERO':
        return <QuantityZero {...props} />
      case 'PURCHASE_FAILED':
        return <PurchaseFailed {...props} />
      case 'SERVER_DEAD':
        return <ServerDead {...props} />
      case 'TOKEN_EXPIRED':
        // return <AddressConfirmation positive={ data.positive } negative={ data.negative } />;
        return null;
      default:
        return null;
    }
  }
  calcMargin = () => {
    const height = this.ref.clientHeight;
    return (window.innerHeight - height) / 2;
  }
  componentDidUpdate(prevProps, prevState) {
    if(source.visible) {
      const top = this.calcMargin() - 50;
      if(prevState.top !== top) {
        this.setState({
          top: top
        })
      }
    }
  }
  render() {
    if(source.visible) {
      return (
        <div className='fixed top-0 right-0 left-0 bottom-0 w-100 z-3 bg-black-20 tc'>
          <div ref={ this.setRef } className='w-80 w-40-m w-third-l bg-white br1 tc center ba b--black-90 bw1' style={{ marginTop: this.state.top }}>
            { this.renderComponent(source.component) }
          </div>
        </div>
      )
    }

    return null;

  }
}

export default Modal;