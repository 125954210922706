import _get from "lodash/get"
import _each from "lodash/each"
import _pullAt from "lodash/pullAt"
import _findIndex from "lodash/findIndex"

const subscriptions = {};

class events {
  static subscribe(event, id, component) {
    const subscribers = _get(subscriptions, event, []);
    const exists = _findIndex(subscribers, ['id', id]);
    if(exists === -1) {
      subscribers.push({
        id: id,
        component: component
      });
      subscriptions[event] = subscribers;
    }
  }
  static unsubscribe(event, id) {
    const subscribers = _get(subscriptions, event, []);
    const exists = _findIndex(subscribers, ['id', id]);
    if(exists > -1) {
      _pullAt(subscribers, exists);
    }
    subscriptions[event] = subscribers;
  }
  static emit(event) {
    const subscribers = _get(subscriptions, event, []);
    if(subscribers.length) {
      _each(subscribers, (sub) => {
        sub.component.forceUpdate();
        if(typeof sub.component.onUpdate !== 'undefined') {
          sub.component.onUpdate();
        }
      });
    }
  }
}
export default events